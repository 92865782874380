import { createSlice } from "@reduxjs/toolkit";

const nutritionFormSlice = createSlice({
  name: "nutritionForm",
  initialState: {
    weight: 0.0,
    body_fat: 0.0,
    objective: 0,
    neat_index: 1.2,
    athlete_type: { id: 1, title: 'General public', description: '', min_range: '1', max_range:'1.2'},
    sex: {id:1, title:"Male", min_range: 0.5, max_range:1.5},
    daily_protein: 0,
    daily_lipid: 0,
  },
  reducers: {
    changeWeight(state, action) {
      state.weight = action.payload;
    },
    changeBodyfat(state, action) {
      state.body_fat= action.payload;
    },
    changeObjective(state, action) {
      state.objective = action.payload;
    },
    changeNeatIndex(state, action) {
      state.neat_index = action.payload;
    },
    changeAthleteType(state, action) {
      state.athlete_type = action.payload;
    },
    changeDailyProtein(state, action) {
      state.daily_protein = action.payload;
    },
    changeDailyLipid(state, action) {
      state.daily_lipid = action.payload;
    },
    changeSex(state, action) {
      state.sex = action.payload;
    },
  }
});

export const { changeWeight, changeBodyfat, changeObjective, changeNeatIndex, changeAthleteType,
  changeDailyProtein, changeDailyLipid, changeSex
} = nutritionFormSlice.actions;
export const nutritionFormReducer = nutritionFormSlice.reducer;
