import { useState } from "react";

function RangeSlider({elementID, min, max, initialValue, onChange}){
  const [value, setValue] = useState(initialValue);
  const [type, setType] = useState("Maintenance");
  const handleChange = (event) =>{
    setValue(event.target.value);
    if (event.target.value < 0) {
      setType("Deficit of ")
    }
    if (event.target.value > 0) {
      setType("Superavit of ")
    }
    if (event.target.value === 0) {
      setType("Maintenance")
    }
    onChange(event.target.value);
  };
  return(
    <div>
      <div>
        {type} {value} %
      </div>
      <input
        type="range"
        className="transparent h-1.5 w-full cursor-pointer appearance-none rounded-lg border-transparent bg-neutral-200"
        id={elementID}
        min={min} max={max}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
}

export default RangeSlider;