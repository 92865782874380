import RangeSlider from './RangeSlider'
import { useDispatch, useSelector } from "react-redux";
import { changeWeight, changeBodyfat, changeObjective, changeNeatIndex, changeAthleteType,
  changeDailyProtein, changeDailyLipid, changeSex
} from '../store'
import RadioGroupInput from './RadioGroupInput';
import MacroRangeSlider from './MacroRangeSlider';

const nutrition_requirements = [
  {name: "Carbohidrates"},
  {name: "Proteins"},
  {name: "Lipids"},
  {name: "KCal"},
]
const neatTypes = [
  { id: 1, title: 'No physical activity', description: 'The subject does not perform physical activity during the day', index: '1.2' },
  { id: 2, title: 'Light physical activity', description: '1-3 trainings a week', index: '1.375' },
  { id: 3, title: 'Moderated physical activity', description: '3-5 trainings a week', index: '1.55' },
  { id: 4, title: 'Heavy physical activity', description: '6-7 trainings a week', index: '1.725' },
  { id: 5, title: 'Very heavy physical activity', description: 'Very hard trainings or jobs involving heavy physical activity i.e. construction', index: '1.9' },
]

const athleteTypes = [
  { id: 1, title: 'General public', description: '', min_range: 1, max_range:1.2},
  { id: 2, title: 'Senior adults', description: 'People >60 years old', min_range: 1.2, max_range:1.6},
  { id: 3, title: 'Endurance athletes', description: '', min_range: 1.6, max_range:2.2},
  { id: 4, title: 'Powerlifting athletes', description: '', min_range: 1.6, max_range:3},
]
const sex =[
  {id:1, title:"Male", min_range: 0.5, max_range:1.5},
  {id:2, title:"Female", min_range: 0.6, max_range:1.5}
];

export default function NutritionForm() {
  const dispatch = useDispatch();

  const handleWeightChange = (event) =>{
    var weight = parseFloat(event.target.value);
    dispatch(changeWeight(weight));
  };
  const handleBodyFatChange = (event) =>{
    dispatch(changeBodyfat(event.target.value));
  };
  const handleRangeChange = (rangeValue) =>{
    var rangeInt = parseInt(rangeValue);
    dispatch(changeObjective(rangeInt));
  };
  const handleActivityIndexChange = (neatType) =>{
    var neatIndex = parseFloat(neatType.index);
    dispatch(changeNeatIndex(neatIndex));
  };

  const handleAthleteTypeChange = (athleteType) =>{
    dispatch(changeAthleteType(athleteType));
  };
  const handleSexTypeChange = (sex) =>{
    dispatch(changeSex(sex));
  };

  const handleProteicRangeChange = (dailyProtein)=>{
    dispatch(changeDailyProtein(dailyProtein/10));
  };
  const handleLipidRangeChange = (dailyLipid)=>{
    dispatch(changeDailyLipid(dailyLipid/10));
  };
  

  const metabolicResult = useSelector(({nutritionForm: {weight, objective, neat_index}}) => {
    var tbm = weight * 22 * neat_index
    var strategy = (100+objective)/100;
    var result = tbm * strategy;
    return result;
  });

  const minDailyProtein = useSelector(({nutritionForm: {athlete_type, objective}}) => {
    if(athlete_type.id === 4 && objective >=0){
      return 1.7
    }else if(athlete_type.id === 4 && objective <=0) {
      return 2.3
    }
    else{
      return athlete_type.min_range
    }
  });
  const maxDailyProtein = useSelector(({nutritionForm: {athlete_type, objective}}) => {
    if(athlete_type.id === 4 && objective >=0){
      return 3.4
    }else if(athlete_type.id === 4 && objective <=0){
      return 3.1
    }else{
      return athlete_type.max_range
    }
  });

  const minDailyLipid = useSelector(({nutritionForm: {sex}}) => {
    return sex.min_range
  });
  const maxDailyLipid = useSelector(({nutritionForm: {sex}}) => {
    return sex.max_range
  });

  const dailyProtein = useSelector(({nutritionForm: {daily_protein}}) => {
    return daily_protein
  });
  const dailyLipid = useSelector(({nutritionForm: {daily_lipid}}) => {
    return daily_lipid
  });
  const weight = useSelector(({nutritionForm: {weight}}) => {
    return weight
  });

  const dailyCarb = useSelector(({nutritionForm: {weight, body_fat, daily_protein, daily_lipid}}) => {
    let lbm = weight * (100-body_fat)/100;
    let procalories = daily_protein * lbm * 4;
    let lipcalories = daily_lipid * weight * 9;
    let carbcalories = metabolicResult - (procalories + lipcalories);
    if(carbcalories ===0){
      return 0
    }
    return carbcalories/4;
  });

  const dailyPerKg = useSelector(({nutritionForm: {weight}}) => {
    if(dailyCarb === 0){
      return 0
    }else{
      return dailyCarb/weight;
    }
  });
  
  const handleCalculate =(event)=>{
    event.preventDefault();
    window.scrollTo(0, 0)
  };
  
  return (
    <div className="space-y-10 divide-y divide-gray-900/10">
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Calculate metabolic needs</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Calculate the kcal needs of the athlete.
          </p>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            The result is:
          </p>
          <table className="min-w-min divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                Macros
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Daily Average
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                g/kg body weight
              </th>
              
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {nutrition_requirements.map((req) => (
              <tr key={req.name}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                  {req.name}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {req.name === "KCal" ? metabolicResult.toFixed(2): ""}
                  {req.name === "Carbohidrates"? dailyCarb.toFixed(2): ""}
                  {req.name === "Proteins" ? dailyProtein*weight: ""}
                  {req.name === "Lipids" ? dailyLipid*weight: ""}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {req.name === "Carbohidrates" ? dailyPerKg.toFixed(2): ""}
                  {req.name === "Proteins" ? dailyProtein: ""}
                  {req.name === "Lipids" ? dailyLipid: ""}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>

        <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label htmlFor="weight" className="block text-sm font-medium leading-6 text-gray-900">
                  Weight(kg)
                </label>
                <div className="mt-2">
                  <input
                    onChange={handleWeightChange}
                    type="text"
                    name="weight"
                    id="weight"
                    autoComplete="weight"
                    placeholder="85.00"
                    className="block w-full rounded-md border-0 py-1.5 pl-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label htmlFor="objective" className="block text-sm font-medium leading-6 text-gray-900">
                  Objective percentage
                </label>
                <div className="mt-2">
                  <RangeSlider elementID="objectiveSlider"
                    min={-25}
                    max={25}
                    initialValue={0}
                    onChange={handleRangeChange}
                  />
                </div>
              </div>
              <div className="col-span-full">
                <RadioGroupInput
                  onChange={handleActivityIndexChange} data={neatTypes} label="Select athlete daily activity"
                />
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Calculate proteic requirements</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">Choose the needs based on the training or diet phase.</p>
        </div>

        <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <RadioGroupInput
                  onChange={handleAthleteTypeChange} data={athleteTypes} label="Select athlete type"
                />
              </div>
              <div className="sm:col-span-3">
                <label htmlFor="body_fat" className="block text-sm font-medium leading-6 text-gray-900">
                  % Body fat
                </label>
                <div className="mt-2">
                  <input
                    onChange={handleBodyFatChange}
                    type="text"
                    name="body_fat"
                    id="body_fat"
                    autoComplete="body_fat"
                    placeholder="20.00"
                    className="block w-full rounded-md border-0 py-1.5 pl-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label htmlFor="protein_range" className="block text-sm font-medium leading-6 text-gray-900">
                  Daily proteic grams range
                </label>
                <div className="mt-2">
                  <MacroRangeSlider elementID="objectiveSlider"
                    min={minDailyProtein * 10}
                    max={maxDailyProtein * 10}
                    initialValue={minDailyProtein * 10}
                    macro_type="Protein"
                    onChange={handleProteicRangeChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Calculate lipid requirements</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">Choose the needs based on the athelete sex and training or diet phase.</p>
        </div>

        <form onSubmit={handleCalculate} className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <RadioGroupInput
                  onChange={handleSexTypeChange} data={sex} label="Select athlete sex"
                />
              </div>
              <div className="sm:col-span-3">
                <label htmlFor="lipid_range" className="block text-sm font-medium leading-6 text-gray-900">
                  Daily lipid grams range
                </label>
                <div className="mt-2">
                  <MacroRangeSlider elementID="objectiveSlider"
                    min={minDailyLipid * 10}
                    max={maxDailyLipid * 10}
                    initialValue={minDailyLipid * 10}
                    macro_type="Lipid"
                    onChange={handleLipidRangeChange}
                  />
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Calculate
              </button>
            </div>
          </div>
          
        </form>
      </div>

    </div>
  )
}