import { useState } from "react";

function MacroRangeSlider({elementID, min, max, initialValue, onChange, macro_type}){
  const [value, setValue] = useState(initialValue);
  const handleChange = (event) =>{
    setValue(event.target.value);
    
    onChange(event.target.value);
  };
  return(
    <div>
      <div>
        {value/10} g/daily of {macro_type}s
      </div>
      <input
        type="range"
        className="transparent h-1.5 w-full cursor-pointer appearance-none rounded-lg border-transparent bg-neutral-200"
        id={elementID}
        min={min} max={max}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
}

export default MacroRangeSlider;