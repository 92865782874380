import { configureStore } from "@reduxjs/toolkit";
import { nutritionFormReducer,changeWeight, changeBodyfat, changeObjective, changeNeatIndex, changeAthleteType, 
  changeDailyProtein, changeDailyLipid, changeSex
} from "./slices/nutritionFormSlice";

const store = configureStore({
  reducer: {
    nutritionForm: nutritionFormReducer,
  }
});

export { store, changeWeight, changeBodyfat, changeObjective, changeNeatIndex ,changeAthleteType,
  changeDailyProtein, changeDailyLipid, changeSex
};
